<template>
	<div class="center-page-minus immerse-wrap">
		<div class="immerse-content">
			<StepBar :step="0" />
			<div class="left">
				<h3 class="title">我的購物車</h3>
			</div>
			<ByCartGrid />
		</div>
		<div style="height: 56px" />
		<ByOrderControl
			:names="['回菜單', '下一步']"
			:events="[goStore, next]"
			:loadings="[false, isNextLoading]"
		></ByOrderControl>
		<ByDialog :title="'請選擇購物方式'" v-model="shopWayModal">
			<p>
				選擇【訪客】進行購物, 則無法查詢歷史訂單紀錄、無法寄發任何通知信件。
			</p>
			<p>
				選擇【會員】進行購物, 可查詢歷史訂購紀錄, 未來可享有現金折扣等優惠。
			</p>
			<template slot="dialog-footer">
				<div class="dialog-footer">
					<ByButton stl="main2" @click="visitors">訪客購物</ByButton>
					<ByButton stl="main1" @click="joinMember">加入會員</ByButton>
				</div>
				<div class="dialog-account">
					<p @click="login">我已經有帳號了</p>
				</div>
			</template>
		</ByDialog>
		<ByCompareCartConfirm ref="compareCartConfirmRef" />
	</div>
</template>

<script>
import StepBar from '../layout/StepBar'

export default {
	components: {
		StepBar,
	},
	data() {
		return {
			shopWayModal: false, //選擇購物方式彈窗
			isNextLoading: false,
		}
	},
	computed: {
		cart() {
			return this.$store.getters['user/currCart']
		},
	},
	watch: {
		cart() {
			if (this.cart.length === 0 && this.$refs.compareCartConfirmRef.isShow) {
				this.$router.push('/')
			}
		},
	},
	methods: {
		goStore() {
			this.$router.push('Store')
		},
		async next() {
			this.isNextLoading = true
			await this.$refs.compareCartConfirmRef.confirm()
			this.isNextLoading = false
		},
		login() {
			this.shopWayModal = false
			this.$store.commit('user/SET_ROUTER', this.$route.fullPath)
			this.$router.push('/Login')
		},
		visitors() {
			this.shopWayModal = false
			this.$router.push('/Order')
		},
		joinMember() {
			this.shopWayModal = false
			this.$store.commit('user/SET_ROUTER', this.$route.fullPath)
			this.$router.push('/register')
		},
	},
}
</script>

<style lang="sass" scoped>
.left
  h3
    text-align: left
    padding-left: 20px
    &.title
      margin: 0
      padding-top: 30px
      padding-bottom: 30px
      border-bottom: 1px solid #D8D8D899

.center-page-minus
  padding: 0

  @media screen and (min-width: 600px)
    margin-bottom: 75px
    padding: initial

.immerse-content
  @media screen and (min-width: 600px)
    width: 425px
    flex: 1
    margin-bottom: 0
</style>
